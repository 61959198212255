import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../Services/auth/auth.service';
import { ApiService } from '../Services/api/api.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.validateSession().pipe(
      map((sessionIsValid) => {
        if (sessionIsValid) {
          return true;
        } else {
          this.router.navigate(['/login'], {
            replaceUrl: true,
            queryParams: { logoutCause: 'session end' },
          });
          return false;
        }
      }),
      catchError(() => {
        this.router.navigate(['/login'], {
          replaceUrl: true,
          queryParams: { logoutCause: 'session end' },
        });
        return of(false);
      })
    );
  }
}
