import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { StateService } from '../state/state.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.apiUrl;
  private headers = new HttpHeaders({
    App_id: 'nlSwUjIBL3QpB8++8OWCog==',
  });

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private authService: AuthService,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  async dismissAllModals() {
    let topModal = await this.modalCtrl.getTop();
    while (topModal) {
      await this.modalCtrl.dismiss();
      topModal = await this.modalCtrl.getTop();
    }
  }

  async handleError403() {
    await this.dismissAllModals();

    this.authService.logout().subscribe({
      next: () => {
        // this.modalCtrl.dismiss(null);
        this.router.navigate(['/login'], {
          replaceUrl: true,
          queryParams: { logoutCause: 'session end' },
        });
      },
      error: (err: any) => {
        console.error('Logout failed:', err);
      },
    });
  }

  initializeBrandingLabels() {
    let initializerRequest = this.http
      .get(`${this.baseUrl}/tenant/settings`, {
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            // this.handleError403();
          }
          return throwError(() => error);
        })
      );

    initializerRequest.subscribe({
      next: (response: any) => {
        console.log(response);
        this.headers = new HttpHeaders({
          App_id: response.app_id,
        });
        console.log('SUCCEEDED ID', response.app_id);
      },
      error: (response: any) => {
        console.log(response);
        this.headers = new HttpHeaders({
          App_id: 'nlSwUjIBL3QpB8++8OWCog==',
        });
        console.log('FAILED ID', response.app_id);
      },
    });

    return initializerRequest;
  }

  getNotifications(): Observable<any[]> {
    console.log(this.headers);

    return this.http
      .get<any[]>(`${this.baseUrl}/user/notifications`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        tap((notifications) => {
          this.stateService.setNotificationsCount(
            notifications.filter((n) => !n.opened).length
          );
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  openedNotification(notificationId: any): Observable<any> {
    return this.http
      .put(
        `${this.baseUrl}/user/notifications/${notificationId}/opened`,
        {},
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getBlockerCards(role: string): Observable<any[]> {
    // console.log(role);
    const params = { role };
    return this.http
      .get<any[]>(`${this.baseUrl}/blockers`, {
        headers: this.headers,
        params,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getOrders(role: string) {
    const params = { role };
    return this.http
      .get(`${this.baseUrl}/orders`, {
        headers: this.headers,
        params,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getPatients() {
    return this.http
      .get(`${this.baseUrl}/patient`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getPatientById(id: string) {
    return this.http
      .get(`${this.baseUrl}/patient/${id}`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getPatientsMedications_sultanAPI(id: string) {
    return this.http
      .get(`${this.baseUrl}/patient/${id}/medications`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getPatientPrescriptions(id: string) {
    return this.http
      .get(`${this.baseUrl}/patient/${id}/prescriptions`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  updatePatientProfile(patientID: any, fileName: File): Observable<any> {
    return this.http
      .put(
        `${this.baseUrl}/patient/${patientID}/avatar`,
        { avatar: fileName.name },
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  uploadPatientProfile(image: File, url: string): Observable<any> {
    return this.http
      .put(url, image, {
        headers: this.headers,
        // withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getOrderPrescriptions(id: string) {
    return this.http
      .get(`${this.baseUrl}/orders/${id}/prescriptions`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getDeliveries(id: string) {
    return this.http
      .get(`${this.baseUrl}/patient/${id}/deliveries`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  resolveBlocker(
    blockerId: any,
    activityId: any,
    type: string,
    value: string
  ): Observable<any> {
    return this.http
      .put(
        `${this.baseUrl}/blockers/resolve`,
        {
          blocker_id: blockerId,
          activity_id: activityId,
          type: type,
          value: value,
        },
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  resolveFormBlocker(
    blockerId: any,
    activityId: any,
    type: string,
    form_data: string
  ): Observable<any> {
    return this.http
      .put(
        `${this.baseUrl}/blockers/resolve`,
        {
          blocker_id: blockerId,
          activity_id: activityId,
          type: type,
          form_data: form_data,
        },
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getDocuments() {
    return this.http
      .get(`${this.baseUrl}/knostos-documents`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  getPatientDocuments(id: string) {
    return this.http
      .get(`${this.baseUrl}/knostos-documents?entity_id=${id}`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  reviewDocument(id: string, reviewResult: string) {
    return this.http
      .put(
        `${this.baseUrl}/knostos-documents/${id}/review`,
        {
          review_result: reviewResult,
          input_data: '',
        },
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  imagePrescribe(
    patientId: string,
    documentType: string,
    fileB64: string,
    fileName: string,
    orderType: string,
    prescriptionType: string,
    documentNote: string
  ) {
    return this.http
      .post(
        `${this.baseUrl}/patient/${patientId}/prescriptions`,
        {
          document_type: documentType,
          file: fileB64,
          file_name: fileName,
          order_type: orderType,
          prescription_type: prescriptionType,
          document_note: documentNote,
        },
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .pipe(
        catchError((error) => {
          console.error('Error Status Code:', error.status);
          if (error.status == 403 || error.status == '403') {
            this.handleError403();
          }
          return throwError(() => error);
        })
      );
  }

  resolveAttachment(
    blockerId: string,
    activityId: string,
    file: File
  ): Observable<any> {
    return new Observable((observer) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64File = reader.result?.toString().split(',')[1]; // Extract Base64 string
        if (!base64File) {
          observer.error('File conversion failed.');
          return;
        }

        // Perform the HTTP PUT request
        this.http
          .put(
            `${this.baseUrl}/blockers/resolve`,
            {
              blocker_id: blockerId,
              activity_id: activityId,
              type: 'UPLOAD_ATTACHMENT',
              file: base64File,
              file_name: file.name,
            },
            {
              headers: this.headers,
              withCredentials: true,
            }
          )
          .subscribe({
            next: (response) => {
              observer.next(response); // Notify the subscriber of the response
              observer.complete(); // Complete the Observable
            },
            error: (error) => {
              observer.error(error); // Pass the error to the subscriber
              console.error('Error Status Code:', error.status);
              this.handleError403();
            }, // Pass the error to the subscriber
          });
      };
      reader.onerror = (error) => {
        observer.error('Error reading file: ' + error); // Notify the subscriber of the error
      };

      // Read the file as Base64
      reader.readAsDataURL(file);
    });
  }
}
